import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import partner1 from '../../assets/images/partner/partner1.png'
import partner2 from '../../assets/images/partner/partner2.png'
import partner3 from '../../assets/images/partner/partner3.png'
import partner4 from '../../assets/images/partner/partner4.png'
import partner5 from '../../assets/images/partner/partner5.png'
import partner6 from '../../assets/images/partner/partner6.png'
import partner7 from '../../assets/images/partner/partner7.png'
import partner8 from '../../assets/images/partner/partner8.png'
import partner9 from '../../assets/images/partner/partner9.png'
import partner10 from '../../assets/images/partner/partner10.png'
import partner11 from '../../assets/images/partner/partner11.png'
import partner12 from '../../assets/images/partner/partner12.png'
import partner13 from '../../assets/images/partner/partner13.png'
import partner14 from '../../assets/images/partner/partner14.png'
import partner15 from '../../assets/images/partner/partner15.png'
import partner16 from '../../assets/images/partner/partner16.png'
import partner17 from '../../assets/images/partner/partner17.png'
import partner18 from '../../assets/images/partner/partner18.png'
import partner19 from '../../assets/images/partner/partner19.png'
import partner20 from '../../assets/images/partner/partner20.png'
import partner21 from '../../assets/images/partner/partner21.png'
import partner22 from '../../assets/images/partner/partner22.png'
import partner23 from '../../assets/images/partner/partner23.png'
import partner24 from '../../assets/images/partner/partner24.png'

const NosPartenaires = () => {
    return (
        <div className="partner-area ptb-70 bg-f1f8fb">
            <div className="container">

                {/*-- PARTENAIRES --*/}
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Nos partenaires
                    </span>
                </div>

                <div className="row ptb-30" id="partenaires">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon color-compliance">
                                <i class='bx bxs-id-card'></i>
                            </div>
                            <h3>
                            Consultants
                            </h3>
                            <p>Nous travaillons avec des experts métiers aussi bien pour développer la plateforme que pour accompagner et conseiller nos clients.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon color-compliance">
                                <i class='bx bxs-network-chart'></i>
                            </div>
                            <h3>
                            Réseau
                            </h3>
                            <p>L’entreprise a rejoint le réseau Valorial pour être au plus près des acteurs du secteur et être à l’écoute des besoins du marché.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon color-compliance">
                                <i class='bx bxs-dollar-circle'></i>
                            </div>
                            <h3>
                            Apporteurs d’affaires
                            </h3>
                            <p>Fournisseurs de logiciel ou bien indépendants, recevez une commission attractive en contrepartie de toute mise en relation.</p>
                        </div>
                    </div>
                    <div className='center pb-70'>
                        <p className='ptb-30'><span className='bold'>Avocats, juristes, consultants RH, qualité ou auditeurs, si vous souhaitez rejoindre notre réseau n’hésitez pas à nous contacter !</span></p>
                        <Link to="/contact" className="default-btn">
                            <i className="flaticon-right"></i> 
                                Nous contacter <span></span>
                        </Link>
                    </div>

                </div>



                {/*-- LOGOS --*/}
                <div className='pbt-70'>
                    <div className="section-title">
                        <span className="sub-title">
                            <img src={starIcon} alt="star" /> 
                            Ils nous font confiance
                        </span>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.1s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner1} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.2s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner2} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.3s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner3} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.4s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner4} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.5s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner5} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.6s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner6} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.1s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner7} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.2s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner8} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.3s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner9} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.4s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner10} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.5s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner11} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.6s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner12} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.1s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner13} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.2s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner14} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.3s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner15} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.4s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner16} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.5s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner17} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.6s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner18} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.1s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner19} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.1s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={partner20} alt="partner" />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.3s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner21} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.4s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner22} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.5s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner23} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                            <ReactWOW delay='.6s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <div className="single-partner-item">
                                        <img src={partner24} alt="partner" />
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="divider"></div>
        </div>
    )
}

export default NosPartenaires