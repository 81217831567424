import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import PageBanner from '../components/Common/PageBanner'
import NosPartenaires from '../components/QuiSommesNous/NosPartenaires'
import Valeurs from "../components/QuiSommesNous/Valeurs"
import ModQSN from '../components/QuiSommesNous/ModQSN'
import NotreEquipe from '../components/QuiSommesNous/NotreEquipe'
import { Helmet } from "react-helmet"
 
const QuiSommesNous = () => {
    return (
        <Layout>
            <Helmet>
                <title>Seekreet | Qui sommes-nous ? Une équipe dédiée à la digitalisation de vos données</title>
                <meta name="description" content="Basée à Rennes, notre équipe vous accompagne dans la gestion de votre digitalisation. Application métier, CRM, data management, sécurisation de vos données..." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Qui sommes-nous ?" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="Qui sommes-nous" 
            />
            <ModQSN />
            <Valeurs />
            <NotreEquipe />
            <NosPartenaires />
            <Footer />
        </Layout>
    );
}

export default QuiSommesNous;