import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import rennes from '../../assets/images/rennes.jpeg'
import bureau from '../../assets/images/bureau.jpg'
import team from '../../assets/images/team.jpg'
import shape1 from '../../assets/images/shape/map-shape1.png'
import shape5 from '../../assets/images/shape/vector-shape5.png'
 
const ModQSN = () => {
    return (
        <section className="about-area ptb-70 bg-e3fbff">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="scientist-box-list">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-md-6 offset-lg-1">
                                    <div className="single-scientist-item">
                                        <img src={bureau} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-scientist-item">
                                        <img src={team} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-12 col-md-6 offset-lg-0 offset-md-3">
                                    <div className="single-scientist-item">
                                        <img src={rennes} alt="banner" />
                                    </div>
                                </div>
                            </div>

                            <div className="map-shape1">
                                <img src={shape1} alt="banner" />
                            </div>
                            <div className="vector-shape5">
                                <img src={shape5} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Qui sommes-nous ?
                                </span>
                                <h2>Seekreet est une agence basée à Rennes, en Bretagne</h2>
                                <p>Fondée en 2018, la vocation de Seekreet est de développer des solutions logicielles et applications métiers complètes et innovantes pour <span className='bold'>aider les entreprises à optimiser et améliorer leurs processus internes</span>.</p>
                                <p>Fort de notre expérience en environnement réseau et en chiffrements de données, nous avons créé <span className='color-compliance'>la solution Wispeek</span>. Une plateforme SaaS qui permet aux entreprises de <span className='bold'>gérer leurs mises en conformité qualité, compliance et RSE</span>.<br></br>
                                Présent sur les marchés du transport, des services et de l’agroalimentaire, nous accompagnons aussi bien les PME/TPE que les grands groupes dans la <span className='bold'>gestion et la sécurisation de leurs informations</span>.</p>
                                <p>C’est un fait que la <span className='bold'>digitalisation</span>, en respectant le savoir-faire, <span className='bold'>est un levier de croissance</span> incontournable pour toute organisation. C’est la raison pour laquelle aujourd’hui, nous accompagnons nos clients dans leurs projets de <span className='bold'>transformation digitale</span> en leur proposant diverses solutions : tablettes et bornes tactiles ; capteurs connectés ; sites internet et des services informatiques liés au  <span className='bold'>data management</span>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModQSN;