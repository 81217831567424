import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import scientist7 from '../../assets/images/scientist/scientist7.png'
import scientist2 from '../../assets/images/scientist/scientist2.png'
import scientist4 from '../../assets/images/scientist/scientist4.png'
import scientist8 from '../../assets/images/scientist/scientist8.png'

const NotreEquipe = () => {
    return (
        <section className="scientist-area bg-color ptb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        La Team Seekreet
                    </span>
                    <h2 className='pb-50'>Notre équipe</h2>
                    <h5>Un bureau d'étude et de developpement basé à Rennes.</h5>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist4} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Guillaume</h3>
                                <span>Gérant</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist2} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Mathis</h3>
                                <span>Chef de produit web-mobile</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist8} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Raouf</h3>
                                <span>CTO Associé Fondateur</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist7} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Sophie</h3>
                                <span>Assistante Commerciale</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default NotreEquipe