import React from 'react'
//import service1 from '../../assets/images/services/service1.png'
import starIcon from '../../assets/images/star-icon.png'
//import process1 from '../../assets/images/process/process1.png'
//import process2 from '../../assets/images/process/process2.png'
//import process3 from '../../assets/images/process/process3.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import course4 from '../../assets/images/courses/course4.jpg'
import course3 from '../../assets/images/courses/course3.jpg'
import course2 from '../../assets/images/courses/course2.jpg'
import valeurs from '../../assets/images/valeurs.png'

const Valeurs = () => {
    return (
        <section className="overview-area ptb-70 bg-f4f7fe">
            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                {/*--- COMPETENCES ---*/}
                <div className="about-content">
                    <div className="content section-title">
                        <span className="sub-title">
                            <img src={starIcon} alt="banner" /> 
                            Nos compétences
                        </span>
                    </div>
                </div>
            
                <div className="about-inner-area pb-50">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image">
                                    <div className="d-block image">
                                        <img src={course4} alt="features" />
                                    </div>
                                </div>
                                <div className="courses-content">
                                    <h3>
                                        Accompagnement dans la concrétisation de votre projet
                                    </h3>              
                                    <p>Nous facilitons le recueil de vos besoins par des méthodologies bien spécifiques lors de notre premier rendez-vous.</p>      
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box ">
                                <div className="courses-image">
                                    <div className="d-block image">
                                        <img src={course3} alt="features" />
                                    </div>
                                </div>
                                <div className="courses-content">
                                    <h3>
                                        Développement avec les technologies adéquates
                                    </h3>
                                    <p>Grâce à l'analyse, nous développons avec performances vos logiciels et applications métiers sur mesure.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="single-courses-box">
                                <div className="courses-image">
                                    <div className="d-block image">
                                        <img src={course2} alt="features" />
                                    </div>
                                </div>
                                <div className="courses-content">
                                    <h3>
                                        Réactivité et proximité dans notre relation client    
                                    </h3>
                                    <p>L’équipe est disponible et réactive pour la livraison, la formation aux outils et le suivi des évolutions techniques.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*--- QUALITE ---*/}
                <div className="ptb-50">
                    <div className="about-content">
                        <div className="content section-title">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Nos engagements qualité
                            </span>
                        </div>
                    </div>
                    <div className='center pb-50'>
                        <p>Tous les membres de l’équipe s’engagent à respecter les normes de qualité dans chacune de nos prestations de création de contenu.</p>
                        <p>Cet engagement se traduit par la mise en place d’une démarche qualité interne qui s’articule autour de 3 axes principaux.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-pricing-box">
                                <div className="pricing-header">
                                    <h3>La satisfaction client</h3>
                                </div>
                                <ul className="pricing-features">
                                    <li><i className='bx bxs-badge-check'></i> Une écoute active et respectueuse des besoins de nos clients</li>
                                    <li><i className='bx bxs-badge-check'></i> Fournir une assistance commerciale et technique compétente et réactive</li>
                                    <li><i className='bx bxs-badge-check'></i> Être innovant et améliorer notre offre de service</li>
                                    <li><i className='bx bxs-badge-check'></i> Être en conformité avec les réglementations en vigueur</li>
                                    <li><i className='bx bxs-badge-check'></i> La confiance renouvelée de nos actionnaires</li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                        

                        <div className="col-lg-4 col-md-6">
                            <div className="single-pricing-box">
                                <div className="pricing-header">
                                    <h3>Un engagement de nos collaborateurs</h3>
                                </div>
                                <ul className="pricing-features">
                                    <li><i className='bx bxs-badge-check'></i> Exigence concernant la qualité et la sécurité des services fournis</li>
                                    <li><i className='bx bxs-badge-check'></i> Développement des compétences personnelles afin de valoriser l’expérience collaborateur</li>
                                    <li><i className='bx bxs-badge-check'></i> Valorisation des prises d’initiative et de l’autonomie dans la réalisation de projets</li>
                                    <li><i className='bx bxs-badge-check'></i> Renforcer la cohésion d’équipe grâce à l'organisation d’événements communs</li>
                                    <li className='pt-10'></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="single-pricing-box">
                                <div className="pricing-header">
                                    <h3>Une politique RSE</h3>
                                </div>

                                <ul className="pricing-features">
                                    <li><i className='bx bxs-badge-check'></i> Respect de l’égalité et de la diversité sur lieu de travail</li>
                                    <li><i className='bx bxs-badge-check'></i> Être à l’écoute des besoins de chacun pour assurer un environnement de bien-être au travail</li>
                                    <li><i className='bx bxs-badge-check'></i> Mise en œuvre d’un cercle qualité interne afin de maintenir une démarche de progrès continu</li>
                                    <li><i className='bx bxs-badge-check'></i> Des initiatives internes pour réduire notre impact environnemental</li>
                                    <li className='pt-10'></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='center'>
                        <h4>Notre système de management de la qualité repose sur la relation de confiance établie entre tous les collaborateurs de l’entreprise.</h4>
                    </div>
                </div>

                {/*--- VALEURS ---*/}
                <div className="pt-50">
                    <div className="about-content">
                        <div className="content section-title">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Nos valeurs
                            </span>
                        </div>
                    </div>
                </div>

                <div className="overview-box">        
                    <div className="overview-image">
                        <div className="image">
                            <img src={valeurs} alt="nos valeurs seekreet" />
                        </div>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content">
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i>  Intégrité</h4>
                                <p>Notre charte éthique interne repose sur le respect de principes éthiques moraux et légaux fin de préserver l’intégrité de l’entreprise et la confiance de nos clients.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Goût du challenge</h4>
                                <p>Notre équipe se tient toujours prête à relever de nouveaux défis pour vous accompagner dans vos projets.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Professionnalisme</h4>
                                <p>Notre équipe est dotée de compétences diverses et complémentaires pour enrichir notre domaine d'activité.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Confidentialité</h4>
                                <p>Nous respectons la confidentialité de nos collaborateurs et de nos clients ainsi que leurs données conformément aux réglementations RGPD.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Innovation</h4>
                                <p>Nous nous devons de proposer les meilleurs services innovants à nos clients afin d’anticiper vos besoins futurs.</p>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </section>
    )
}

export default Valeurs